<template>
  <div class="inner-div">
    <VasionSchedulersPage title="Barcode Recognition" />
  </div>
</template>
<script>
export default {
  name: 'BarcodeRecognition',
}
</script>
<style lang="scss" scoped>

  .inner-div {
    padding-top: 8px;
    margin-right: 8px;
  }

</style>
